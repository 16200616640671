<template>
  <div>
    <ImageComponent
      :src="media[0].src"
      :alt="media[0].caption"
      @click="openGallery(0)"
    >
      <template v-slot:headline>
        <h2>fatherland: monument to freedom</h2>
        <h5>2017 - ongoing</h5>
        <h5>
          <a
            href="/assets/fatherland/fatherland-visual-material.pdf"
            target="_blank"
            >project documentation (pdf)</a
          >
        </h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12 text-left">
            <p>
              Fatherland: A Monument to Freedom is a long-term documentary
              project (2017- ongoing) and revolves around the attempt to
              revitalize a monumental complex called the Monument to Freedom in
              Kochani, North Macedonia. The memorial site is a collaboration
              between artist Gligor Chemerski and architect Radovan Radjenovic.
              It has been erected in 1981 while the country was still a part of
              the SFR Yugoslavia, to honor of the 40th anniversary of the
              People’s Liberation Struggle, the end of which was marked by the
              end of WWII and victory over Fascism. The Monument to Freedom has
              since been used as a public space with an integrated amphitheater
              where the lives of the community that inhabits it can intertwine.
            </p>
            <p>
              Fatherland: A Monument to Freedom is a multidisciplinary project
              that has included many collaborations with artists and cultural
              workers from across the spectrum and the local community of
              Kochani, whose stories, experiences and contributions have
              enriched and nuanced the project remarkably.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left iframe-container">
            <iframe
              title="vimeo-player"
              src="https://player.vimeo.com/video/230262070"
              class="responsive-iframe"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </template>
    </ImageComponent>

    <div class="row">
      <div class="col-12 col-lg-3 text-left mt-4">
        <h2>AKTO festival for contemporary art</h2>
        <h5>2017</h5>
      </div>
      <div class="col-12 col-lg-9 text-left mt-4">
        <div>
          <p>
            The project began in 2017, when, for the summer program of the 12th
            edition of the AKTO Festival for Contemporary Art in Bitola, North
            Macedonia, in a collaboration between artists Elena Chemerska,
            Natasha Nedelkova, Filip Jovanovski and Dragan Mishev a video was
            produced for a song titled Freedom by Skopje band The John.
          </p>
          <p>
            For the opening of the festival, the messages once casted in bronze
            that were placed on the side walls of the monument to Freedom in
            Kochani were placed at the entrance of the the Magaza Gallery.
          </p>
          <p>
            A publication was produced in collaboration with Архи.тек (a
            publication focusing on architecture and its wider cultural context)
            in which some of the ideas embodied in the Monument to Freedom in
            Kochani were reintroduced to a fresh public.
          </p>
        </div>
      </div>
    </div>
    <ImageComponent
      :src="media[1].src"
      :alt="media[1].caption"
      @click="openGallery(1)"
    >
      <template v-slot:headline>
        <h2>salonica city of ghosts</h2>
        <h5>2018</h5>
      </template>
      <template v-slot:description>
        <p>
          In 2018, in a collaboration with the Theatre of Cvetko the Navigator
          from Skopje, the play Salonica, City of Ghosts-Christians, Muslims and
          Jews 1430-1950 was performed for the first time at the Monument to
          Freedom in Kochani.
        </p>
        <p>
          The play follows the life and events of the city of Salonica from the
          Ottoman capture in 1430 up until the Holocaust and the deportation of
          45 000 Jews from Thessaloniki to the Auschwitz Concentration Camp,
          covering a considerable portion of the convoluted history of the
          Balkans through a specific perspective, in a humane and often times
          humorous way. The night of the performance came to be in its own
          accord a truly festive occasion for the people of Kochani and for the
          site of the monument as well.
        </p>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[2].caption"
              v-lazy="media[2].src"
              @click="openGallery(2)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[3].caption"
              v-lazy="media[3].src"
              @click="openGallery(3)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[4].caption"
              v-lazy="media[4].src"
              @click="openGallery(4)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[5].caption"
              v-lazy="media[5].src"
              @click="openGallery(5)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[6].caption"
              v-lazy="media[6].src"
              @click="openGallery(6)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              The play is developed by Rusomir Bogdanovski, directed by Slobodan
              Unkovski, produced by the Theatre of Cvetko the Navigator and
              featuring original music by Zlatko Origanski played live at each
              performance.
            </p>
            <p>
              The performance at the Monument to Freedom in Kochani was kindly
              supported by the Municipality of Kochani and the Friedrich Ebert
              Foundation.
            </p>
          </div>
        </div>
      </template>
    </ImageComponent>
    <ImageComponent
      :src="media[7].src"
      :alt="media[7].caption"
      @click="openGallery(7)"
    >
      <template v-slot:headline>
        <h2 class="line-height_08">spomenik</h2>
        <h2 class="line-height_08">naslobodata</h2>
        <h2 class="line-height_08">.mk</h2>
        <h5>2018</h5>
      </template>
      <template v-slot:description>
        <p>
          This platform was created in collaboration with the creative studio
          <a href="http://thisiskiosk.com" target="_blank">KIOSK</a> from
          Skopje, whose co-founders, Anastazija Manasievska and Miki Stefanoski
          generously invested their time, attention and effort in its creation
          as a selfless gesture of support for local initiatives with a social
          character.
        </p>
        <p>
          This platform is imagined to function as a kind of a diary of the
          Monument to Freedom in Kochani.
        </p>
        <p>
          A place where the people of Kochani, but also of course, anyone else
          who has memories connected to the monument can share their stories and
          see, hear, experience the stories of others with whom they share this
          specific place. A place that has appeared in the stories of many as a
          place where they had their first kiss, first love, first drink, first
          fight, and many, many sunrises.
        </p>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[8].caption"
              v-lazy="media[8].src"
              @click="openGallery(8)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[9].caption"
              v-lazy="media[9].src"
              @click="openGallery(9)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[10].caption"
              v-lazy="media[10].src"
              @click="openGallery(10)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[11].caption"
              v-lazy="media[11].src"
              @click="openGallery(11)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[12].caption"
              v-lazy="media[12].src"
              @click="openGallery(12)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              For an overview of the contributions made to the platform, please
              visit the following link:
              <a href="https://spomeniknaslobodata.mk/" target="_blank"
                >spomeniknaslobodata.mk</a
              >
            </p>
          </div>
        </div>
      </template>
    </ImageComponent>
    <ImageComponent
      :src="media[13].src"
      :alt="media[13].caption"
      @click="openGallery(13)"
    >
      <template v-slot:headline>
        <h2>monument to freedom - conversations</h2>
        <h5>2019</h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <p>
              The book “Monument to Freedom - Conversations”, published by
              <a href="http://privateprint.mk/" target="_blank">PrivatePrint</a
              >, is a documentary project by Elena Chemerska consisted of
              interviews about the Monument to Freedom in Kočani (a work by
              Gligor Chemerski and Radovan Ragjenovikj) focused on its artistic,
              architectural, and socio-cultural aspects. The book includes
              articles and essays connected to the monument, as well as archival
              and visual material. The book is bilingual, Macedonian and
              English.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[14].caption"
              v-lazy="media[14].src"
              @click="openGallery(14)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[15].caption"
              v-lazy="media[15].src"
              @click="openGallery(15)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[16].caption"
              v-lazy="media[16].src"
              @click="openGallery(16)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[17].caption"
              v-lazy="media[17].src"
              @click="openGallery(17)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              Interviews and essays in the book: Radovan Ragjenovikj, Gligor
              Chemerski, Stevan Stanić, Ilija Penušliski, Danica Pavlovska,
              Kosta Balabanov, Nebojsha Vilikj, Aneta Hristova - Popovska,
              Antoanela Petkovska, Sanja Horvatinčić, Boris Petkovski, Vladimir
              Martinovski.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4 iframe-container">
            <iframe
              src="https://www.youtube.com/embed/Bqm0nYrEUjs"
              frameborder="0"
              class="responsive-iframe"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </template>
    </ImageComponent>
    <ImageComponent
      :src="media[18].src"
      :alt="media[18].caption"
      @click="openGallery(18)"
    >
      <template v-slot:headline>
        <h2>fatherland: monument to freedom</h2>
        <h5>
          two channel video installation, 19’53, 12’04; oil on canvas, 495 cm x
          280 cm; 190 cm x 150 cm, 190 cm x 150 cm; archive room.
        </h5>
        <h5>2019</h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <p>
              The installation Fatherland: A Monument to Freedom has been
              designed to bring the multifaceted work done around the
              revitalization of the Monument to Freedom into an exhibition
              format.
            </p>
            <p>
              The documented site-specific installation consists of a large
              canvas depicting one of the mosaic friezes from the Monument to
              Freedom, namely, the frieze Warm Land; the two smaller paintings
              Where the Ghosts and the Living Gather for Drinks and Untitled; a
              two channel video installation placed behind the painting-object
              Warm Land assembled from documentary material produced and
              archival material collected during the intense research done
              between 2017-2019; and an archival room showing original archival
              material gathered from local and national official and private
              archives alike.
            </p>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[19].caption"
                  v-lazy="media[19].src"
                  @click="openGallery(19)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 iframe-container-small mt-4">
                <iframe
                  title="vimeo-player"
                  src="https://player.vimeo.com/video/433305062"
                  frameborder="0"
                  class="responsive-iframe"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="col-6 iframe-container-small mt-4">
                <iframe
                  title="vimeo-player"
                  src="https://player.vimeo.com/video/432825130"
                  class="responsive-iframe"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[20].caption"
                  v-lazy="media[20].src"
                  @click="openGallery(20)"
                />
              </div>
              <div class="col-12 col-lg-6 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[21].caption"
                  v-lazy="media[21].src"
                  @click="openGallery(21)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[22].caption"
                  v-lazy="media[22].src"
                  @click="openGallery(22)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[23].caption"
                  v-lazy="media[23].src"
                  @click="openGallery(23)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[24].caption"
                  v-lazy="media[24].src"
                  @click="openGallery(24)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[25].caption"
                  v-lazy="media[25].src"
                  @click="openGallery(25)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[26].caption"
                  v-lazy="media[26].src"
                  @click="openGallery(26)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </ImageComponent>
    <ImageComponent
      :src="media[27].src"
      :alt="media[27].caption"
      @click="openGallery(27)"
    >
      <template v-slot:headline>
        <h2>resort 2022</h2>
        <h5>
          collection by jovana filipovic
        </h5>
        <h5>2021</h5>
        <h5>
          <a
            href="/assets/fatherland/jovana-filipovic-resort-2022.pdf"
            target="_blank"
            >catalogue (pdf)</a
          >
        </h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <p>
              Drawing from the Monument to Freedom in Kochani and Elena
              Chemerska’s book Monument to Freedom - Conversations (Private
              Print, 2019), in her new collection Resort 2022, Skopje-based
              fashion designer Jovana Filipovic explores her own take on the
              idea of freedom.
            </p>
            <p>
              “Wanting to help Elena in her struggle to restore and revive the
              Monument to Freedom, five percent of the sales of the Resort 2022
              collection will go to its restoration. I hope that this
              collaboration will contribute in raising collective awareness and
              the will of people, but primarily of the institutions whose
              responsibility is to take care of the cultural heritage” - says
              Filipovic.
            </p>
            <div class="row">
              <div class="col-12 col-lg-6 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[28].caption"
                  v-lazy="media[28].src"
                  @click="openGallery(28)"
                />
              </div>
              <div class="col-12 col-lg-6 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[29].caption"
                  v-lazy="media[29].src"
                  @click="openGallery(29)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <p>
                  “To a large extent the collection is inspired by the Monument
                  to Freedom, its form, the story it tells, the colours of the
                  mosaics. The love and passion I sense in that space and that
                  the authors Chemerski and Radjenovic wanted to convey to the
                  ear of the generations of the future.“
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[30].caption"
                  v-lazy="media[30].src"
                  @click="openGallery(30)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <p>
                  “In that sense, I was going for looks which although bare
                  subtle qualities, carry within them a strong sense of movement
                  and energy, and a powerful message. Easy to wear and combine,
                  letting the person wearing them free their imagination when
                  matching the pieces. "Some of the cuts are reminiscent of the
                  blueprint of an early Christian basilica which is inscribed in
                  the base of the monument, humorously playing with the ancient
                  amphitheatre shape, building upon the past and opening up to
                  the future".
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[31].caption"
                  v-lazy="media[31].src"
                  @click="openGallery(31)"
                />
              </div>
              <div class="col-12 col-lg-6 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[32].caption"
                  v-lazy="media[32].src"
                  @click="openGallery(32)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </ImageComponent>
    <ImageComponent
      :src="media[33].src"
      :alt="media[33].caption"
      @click="openGallery(33)"
    >
      <template v-slot:headline>
        <h2>fly lokubija fly</h2>
        <h5>
          interactive workshop,
        </h5>
        <h5>2022</h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <p>
              In the summer of 2022, within the framework of “Fatherland: A
              Monument to Freedom”- in essence a long-term series of attempts to
              revive the utopic program of the Monument to Freedom and the
              emancipatory potential of this work of art for the community
              surrounding it as seen from the perspective of today’s economic,
              social, cultural and ecological crisis- the two-day workshop
              spanning across the period of 30 days “Fly Lokubija Fly” took
              place.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[34].caption"
              v-lazy="media[34].src"
              @click="openGallery(34)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              The concept of the workshop came out of the stories I gathered of
              members of different generations of the local community that are
              related to the informal tradition of bringing the children of the
              town to fly kites at the Monument to Freedom. Placed on the top of
              Lokubija hill where the sun and the wind are always present, and
              overlooking the whole surface of Kochani, the rice fields and the
              mountain massifs beyond, the open and wide structure of the
              Monument to Freedom became the perfect location for these
              activities.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[35].caption"
              v-lazy="media[35].src"
              @click="openGallery(35)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              “Fly Lokubija Fly” was imagined as a two-day interactive workshop
              for the youngest citizens of Kochani and the region, anticipating
              the creation of kites made of recycled material gathered from the
              local landfill, and based on the drawings that the children
              participants would create during the workshop at the monument
              itself, that may or may not be inspired by the specific experience
              of being in that space. The workshop was conducted in
              collaboration with visual artist Dorotej Neshovski.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[36].caption"
              v-lazy="media[36].src"
              @click="openGallery(36)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[37].caption"
              v-lazy="media[37].src"
              @click="openGallery(37)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              “Fly Lokubija Fly” invited all children living on Lokubija hill,
              Kochani and the wider region curious about flying, flying objects
              and their making. Especially encouraged was the participation of
              Roma children- the population of Lokubija hill is in largest
              percent of Roma origin, working predominantly with garbage
              collection and transportation, thus directly participating in the
              production of the materials that would be used to make the flying
              objects the children would fly.
            </p>
            <p>
              During the first part of the workshop, we got to know the
              children, and they got to know each other and find out a bit more
              about the story of the Monument to Freedom. We talked about some
              of the visual qualities and ways of depicting and telling a story
              that we could see in the mosaic ensemble surrounding us, together
              forming 330 m2 of mosaic divided into 8 compositions/friezes
              floating around the open theatre structure of the monument.
            </p>
            <p>
              Now we were ready to enter the drawing phase, during which the
              children made countless of wonderful drawings, paintings and
              collages in which they drew inspiration from the work of art we
              inhabited, the environment that surrounded us, and their own
              memories and fantasies.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[38].caption"
              v-lazy="media[38].src"
              @click="openGallery(38)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[39].caption"
              v-lazy="media[39].src"
              @click="openGallery(39)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[40].caption"
              v-lazy="media[40].src"
              @click="openGallery(40)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              After the end of the first part of the workshop, we adapted the
              children’s drawings to fit the shapes of the canvases which would
              condition the kites to fly. So adapted, the drawings were then
              printed on recycled plastic that was obtained from Kochani’s
              landfill, thanks to Eco Project-Ko from Kochani and Agropal
              Company from Tetovo, North Macedonia, who agreed to collaborate
              with us for this workshop and provide us with the necessary
              recycled material, being that Kochani does not have a local
              capacity for recycling plastic.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[41].caption"
              v-lazy="media[41].src"
              @click="openGallery(41)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[42].caption"
              v-lazy="media[42].src"
              @click="openGallery(42)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[43].caption"
              v-lazy="media[43].src"
              @click="openGallery(43)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              On the second day of the workshop, in collaboration with
              professional kite makers, the children completed the kites made of
              recycled plastic from the Kochani landfill on the basis of their
              drawings and flew the kites over the Monument to Freedom. They
              learned all that is necessary to be able to model and make a kite
              on their own, but were also introduced to the basics of the
              recycling practice and the labor employed to produce the materials
              that the kites were made of.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[44].caption"
              v-lazy="media[44].src"
              @click="openGallery(44)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[45].caption"
              v-lazy="media[45].src"
              @click="openGallery(45)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[46].caption"
              v-lazy="media[46].src"
              @click="openGallery(46)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[47].caption"
              v-lazy="media[47].src"
              @click="openGallery(47)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            At the end of the workshop, each child took home their kite, which
            would patiently wait for their next flight over Lokubija.
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[48].caption"
              v-lazy="media[48].src"
              @click="openGallery(48)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            The project was realized in collaboration with SCS Centar-Jadro in
            the framework of the project “Spaces of Generosity- Transformative
            Stations” supported by the Open Society Foundation Macedonia.
          </div>
        </div>
      </template>
    </ImageComponent>
    <ImageComponent
      :src="media[49].src"
      :alt="media[49].caption"
      @click="openGallery(49)"
    >
      <template v-slot:headline>
        <h2>fly lokubija fly</h2>
        <h5>two- channel video installation</h5>
        <h5>15'52</h5>
        <h5>
          kite- objects produced during the site-specific workshop “fly lokubija
          fly”
        </h5>
        <h5>2022</h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              Based on the work done in the course of “Fly Lokubija Fly” and
              previously taken actions directed at reviving some of the inherent
              emancipatory potentials of the Monument to Freedom, within the
              framework of the SCS Centar-Jadro project “Spaces of Generosity-
              Transformative Stations”, a docufiction video work was produced
              weaving several elements of the research together in a video
              installation format.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[50].caption"
              v-lazy="media[50].src"
              @click="openGallery(50)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              The video brings together research done with members of the local
              community reflecting on the meaning the edifice has had in their
              personal life and the shifting social and economic context of
              their hometown of Kochani; a speculative site-specific light
              installation referencing a set of bronze casts that have been
              stolen from the site and sold as old iron during the challenging
              90s’. These bronze reliefs, originally four in number, once bore
              the names of the people from the region who lost their lives in
              the antifascist struggle, accompanied by two quotations referring
              to the concept of freedom as an objective for society. For this
              iteration of the work, the missing quotation by early 20th century
              Macedonian revolutionary Goce Delchev was recreated on the part of
              the monument walls where it once stood, reading: “We need freedom.
              As we rule the night, we will also rule the day. And this we shall
              accomplish, or perish”. In the absence of its physical body, the
              meaning of these words continues to echo in the ephemeral quality
              of light.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[51].caption"
              v-lazy="media[51].src"
              @click="openGallery(51)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[52].caption"
              v-lazy="media[52].src"
              @click="openGallery(52)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[53].caption"
              v-lazy="media[53].src"
              @click="openGallery(53)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[54].caption"
              v-lazy="media[54].src"
              @click="openGallery(54)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              The third part of the video shows documentation of the workshop
              “Fly Lokubija Fly” carried out with the youngest citizens of
              Kochani. “Fly Lokubija Fly” was designed based on the stories
              gathered from locals from different generations, of the informal
              tradition of bringing children to fly kites at the site of the
              Monument to Freedom.
            </p>
            <p>
              For the workshop, all children living primarily on Lokubija hill,
              but also Kochani and the wider region, curious about flying,
              flying objects, and their making were invited to join. Especially
              encouraged was the participation of Roma children- the population
              of Lokubija hill is in largest percent of Roma origin, working
              predominantly with garbage collection and transportation, thus
              directly participating in the production of the materials that
              would be used to make the flying objects the children would fly.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[55].caption"
              v-lazy="media[55].src"
              @click="openGallery(55)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              More than only kite-making, through the creative processes,
              developed together with the children participants, the workshop
              was focused on the collective discovery of forms of freedom in
              everyday life. The kites produced were developed based on the
              drawings made in situ with the children participants in the
              workshop, and were printed on recycled plastic made from materials
              donated by the Kochani landfill for “Fly Lokubija Fly”.
            </p>
            <span class="caption-30">collaborators</span>
            <ul class="standard-list no-padding-list">
              <li>
                Camera operators: Gjorgi Klincarov, Marina Koloska
              </li>
              <li>
                Drone operator: Darko Danilov
              </li>
              <li>
                Sound: Marko Rikalovski
              </li>
              <li>
                Editor: Dejan Smileski
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left iframe-container mt-3">
            <iframe
              title="vimeo-player"
              src="https://player.vimeo.com/video/822374217"
              class="responsive-iframe"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[56].caption"
              v-lazy="media[56].src"
              @click="openGallery(56)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[57].caption"
              v-lazy="media[57].src"
              @click="openGallery(57)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[58].caption"
              v-lazy="media[58].src"
              @click="openGallery(58)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[59].caption"
              v-lazy="media[59].src"
              @click="openGallery(59)"
            />
          </div>
        </div>
      </template>
    </ImageComponent>
    <ImageComponent
      :src="media[60].src"
      :alt="media[60].caption"
      @click="openGallery(60)"
    >
      <template v-slot:headline>
        <h2>restoration and conservation</h2>
        <h5>2023/24</h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <p>
              The Monument to Freedom has long stood as a testament to the
              region’s historical and cultural aspirations. Within the project
              Fatherland: A Monument to Freedom, a team of conservation experts,
              legal professionals, architects, and artists was assembled to
              write a project proposal for the conservation and restoration of
              the monument. In collaboration with the municipality of Kochani,
              this proposal was applied to several funding opportunities,
              ultimately being awarded by the prestigious US Ambassadors Fund
              for Cultural Preservation (AFCP).
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[61].caption"
              v-lazy="media[61].src"
              @click="openGallery(61)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              Designed as a symbol of liberation and collective memory, the
              monument had fallen into neglect, its original vision dimmed by
              the passage of time and shifting socio-political landscapes.
              Through an intensive restoration project, this emblem of
              resilience has now been given a second life.
            </p>
            <p>
              As the first modernist edifice in North Macedonia to receive the
              prestigious US Ambassadors Fund for Cultural Preservation grant,
              the restoration marks a historic milestone for the preservation of
              the country's modernist heritage. This recognition underscores the
              monument’s importance not only as an architectural landmark but
              also as a cultural touchstone, embodying unrealized futures and
              collective aspirations.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[62].caption"
              v-lazy="media[62].src"
              @click="openGallery(62)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[63].caption"
              v-lazy="media[63].src"
              @click="openGallery(63)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[64].caption"
              v-lazy="media[64].src"
              @click="openGallery(64)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[65].caption"
              v-lazy="media[65].src"
              @click="openGallery(65)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[66].caption"
              v-lazy="media[66].src"
              @click="openGallery(66)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[67].caption"
              v-lazy="media[67].src"
              @click="openGallery(67)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[68].caption"
              v-lazy="media[68].src"
              @click="openGallery(68)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[69].caption"
              v-lazy="media[69].src"
              @click="openGallery(69)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[70].caption"
              v-lazy="media[70].src"
              @click="openGallery(70)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              The restoration process was collaborative, integrating expertise
              in architecture, art, and history. Beyond repairing the physical
              structure, the project sought to reconcile the monument’s symbolic
              and cultural significance, breathing new life into its narrative.
              By embracing themes of legacy, grief, and transformation, the
              restoration became an act of care—an effort to honor the past
              while making space for renewed meaning.
            </p>
            <p>
              In architectural segment of the conservation and restoration
              project, the design and project supervision were led by M.Sc.
              Cvetanka Hadji-Pecova, with the participation of architects Sanja
              Rađenović Jovanović and Nenad Rađenović from Studio “R.” The
              contractor for this segment was the company “Istok Mermer.”
            </p>
            <p>
              The team responsible for the conservation and restoration of the
              mosaic friezes on the Monument to Freedom was led by Vladimir
              Simeonov, with team members Nikola Kekenovski, Dragan Vergovski,
              Toshe Peshovski, and Elena Chemerska, who carried out the direct
              conservation and restoration of the friezes.
            </p>
            <p>
              The project coordinator for the overall conservation and
              restoration of the Monument to Freedom in Kočani was Rajna Krtov
              Chemerska. The expert supervision was conducted by the Institute
              for the Protection of Cultural Monuments and Museum – Štip. The
              further care and maintenance of the restored Monument to Freedom
              have been entrusted to the Municipality of Kočani.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[71].caption"
              v-lazy="media[71].src"
              @click="openGallery(71)"
            />
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <img
              class="img-fluid"
              :alt="media[72].caption"
              v-lazy="media[72].src"
              @click="openGallery(72)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <img
              class="img-fluid"
              :alt="media[73].caption"
              v-lazy="media[73].src"
              @click="openGallery(73)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-4">
            <p>
              This journey also inspired a creative exploration in the form of a
              documentary film, currently in its final stages of production. The
              film delves into the monument’s story, tracing its journey from
              neglect to renewal and highlighting the power of art and
              collective effort to reimagine the future.
            </p>
            <p>
              Through this work, the Monument to Freedom stands not only as a
              restored structure but as a living dialogue between history and
              possibility—a space where past, present, and future converge.
            </p>
          </div>
        </div>
      </template>
    </ImageComponent>
    <Gallery :media="media" />
  </div>
</template>

<script>
import EventBus from "@/eventBus";
import Gallery from "@/Gallery";
import ImageComponent from "@/ImageComponent";

export default {
  name: "Fatherland",
  components: { Gallery, ImageComponent },
  data() {
    return {
      media: [
        {
          src: "/assets/fatherland/00.jpg",
          caption:
            "Monument to Freedom, Kochani, North Macedonia. Day of the performance of Salonica, City of Ghosts: Christians, Muslims and Jews 1430-1950. 20.08.2018. "
        },
        {
          src: "/assets/fatherland/5.jpg",
          caption:
            "Night of the performance of Salonica, City of Ghosts: Christians, Muslims and Jews 1430-1950 at the Monument to Freedom. 20.08.2018."
        },
        {
          src: "/assets/fatherland/7.jpg",
          caption:
            "The crew of the Theater of Navigator Cvetko practicing for the performance at the Monument to Freedom. 2018."
        },
        {
          src: "/assets/fatherland/6.jpg",
          caption:
            "The crew of the Theater of Navigator Cvetko practicing for the performance of Salonica, City of Ghosts-Christians, Muslims and Jews 1430-1950 at the Monument to Freedom. 2018."
        },
        {
          src: "/assets/fatherland/8.jpg",
          caption:
            "Night of the performance of Salonica, City of Ghosts- Christians, Muslims and Jews 1430-1950 at the Monument to Freedom. Kochani, North Macedonia. 20.08. 2018."
        },
        {
          src: "/assets/fatherland/9.jpg",
          caption:
            "Performance of Salonica, City of Ghosts- Christians, Muslims and Jews 1430-1950 at the Monument to Freedom. Kochani, North Macedonia. 20.08. 2018."
        },
        {
          src: "/assets/fatherland/10.jpg",
          caption:
            "Performance of Salonica, City of Ghosts- Christians, Muslims and Jews 1430-1950 at the Monument to Freedom. Kochani, North Macedonia. 20.08. 2018."
        },
        {
          src: "/assets/fatherland/11.jpg",
          caption:
            "From the personal archive of Martin Milev; contribution to <a href='https://spomeniknaslobodata.mk/' target='_blank'>spomeniknaslobodata.mk</a>. 2020."
        },
        {
          src: "/assets/fatherland/20.jpg",
          caption:
            "From the family archive of Elena Chemerska; contribution to <a href='https://spomeniknaslobodata.mk/' target='_blank'>spomeniknaslobodata.mk</a>. 2018."
        },
        {
          src: "/assets/fatherland/17.jpg",
          caption:
            "From the personal archive of Vladimir Simeonov; contribution to <a href='https://spomeniknaslobodata.mk/' target='_blank'>spomeniknaslobodata.mk</a>. 2018."
        },
        {
          src: "/assets/fatherland/18.jpg",
          caption:
            "From the personal archive of Ana Maria Hazurova; contribution to <a href='https://spomeniknaslobodata.mk/' target='_blank'>spomeniknaslobodata.mk</a>. 2021."
        },
        {
          src: "/assets/fatherland/15.jpg",
          caption:
            "From the personal archive of Julijana Vuchkova; contribution to <a href='https://spomeniknaslobodata.mk/' target='_blank'>spomeniknaslobodata.mk</a>. 2018."
        },
        {
          src: "/assets/fatherland/19.jpg",
          caption:
            "From the personal archive of Lilika Strezoska; contribution to <a href='https://spomeniknaslobodata.mk/' target='_blank'>spomeniknaslobodata.mk</a>. 2018. "
        },
        {
          src: "/assets/fatherland/21.jpg",
          caption:
            "Monument to Freedom – Conversations, 2019, Skopje, Private Print."
        },
        {
          src: "/assets/fatherland/23.jpg",
          caption:
            "Monument to Freedom – Conversations, 2019, Skopje, Private Print."
        },
        {
          src: "/assets/fatherland/25.jpg",
          caption:
            "Monument to Freedom – Conversations, 2019, Skopje, Private Print."
        },
        {
          src: "/assets/fatherland/22.jpg",
          caption:
            "Monument to Freedom – Conversations, 2019, Skopje, Private Print."
        },
        {
          src: "/assets/fatherland/24.jpg",
          caption:
            "Monument to Freedom – Conversations, 2019, Skopje, Private Print."
        },
        {
          src: "/assets/fatherland/31.jpg",
          caption:
            "Installation View: Fatherland: A Monument to Freedom. 2019. Master Institute of Visual Cultures, St. Joost School of Art and Design. ‘s-Hertogenbosch, the Netherlands."
        },
        {
          src: "/assets/fatherland/32.jpg",
          caption:
            "Installation View: Fatherland: A Monument to Freedom. 2019. Master Institute of Visual Cultures, St. Joost School of Art and Design. ‘s-Hertogenbosch, the Netherlands."
        },
        {
          src: "/assets/fatherland/33.jpg",
          caption:
            "Installation View: Fatherland: A Monument to Freedom. 2019. Master Institute of Visual Cultures, St. Joost School of Art and Design. ‘s-Hertogenbosch, the Netherlands."
        },
        {
          src: "/assets/fatherland/34.jpg",
          caption:
            "Warm Land-detail, 495 cm x 280 cm, 2019. Master Institute of Visual Cultures, St. Joost School of Art and Design. ‘s-Hertogenbosch, the Netherlands."
        },
        {
          src: "/assets/fatherland/35.jpg",
          caption:
            "Installation View: Fatherland: A Monument to Freedom. 2019. Master Institute of Visual Cultures, St. Joost School of Art and Design. ‘s-Hertogenbosch, the Netherlands."
        },
        {
          src: "/assets/fatherland/36.jpg",
          caption:
            "Archive Room: Fatherland: A Monument to Freedom. 2019. Master Institute of Visual Cultures, St. Joost School of Art and Design. ‘s-Hertogenbosch, the Netherlands."
        },
        {
          src: "/assets/fatherland/39.jpg",
          caption:
            "Monument to Freedom - Conversations, solo show and book launch. Private Print Studio, Skopje, North Macedonia. 2019."
        },
        {
          src: "/assets/fatherland/40.jpg",
          caption:
            "Monument to Freedom - Conversations, solo show and book launch. Private Print Studio, Skopje, North Macedonia. 2019."
        },
        {
          src: "/assets/fatherland/38.jpg",
          caption:
            "Monument to Freedom - Conversations, solo show and book launch. Private Print Studio, Skopje, North Macedonia. 2019."
        },
        {
          src: "/assets/fatherland/41.png",
          caption: "Jovana Filipovic RESORT 2022"
        },
        {
          src: "/assets/fatherland/42.jpg",
          caption: "Jovana Filipovic RESORT 2022"
        },
        {
          src: "/assets/fatherland/43.jpg",
          caption: "Jovana Filipovic RESORT 2022"
        },
        {
          src: "/assets/fatherland/44.jpg",
          caption: "Jovana Filipovic RESORT 2022"
        },
        {
          src: "/assets/fatherland/45.jpg",
          caption: "Jovana Filipovic RESORT 2022"
        },
        {
          src: "/assets/fatherland/46.jpg",
          caption: "Jovana Filipovic RESORT 2022"
        },
        {
          src: "/assets/fatherland/47.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 2.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/48.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 1.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/49.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 1.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/50.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 1.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/51.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 1.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/52.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 1.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/53.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 1.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/54.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 1.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/55.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 2.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/56.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 2.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/57.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 2.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/58.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 2.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/59.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 2.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/60.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 2.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/61.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 2.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/62.jpg",
          caption:
            "Fly Lokubija Fly workshop: Day 2.<br/>Monument to Freedom, Kochani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/67.jpg",
          caption:
            "Installation view: Fatherland: A Monument to Freedom/Fly Lokubija Fly. 2022. SCS Centar-Jadro. Skopje, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/63.jpg",
          caption:
            "Installation view: Fatherland: A Monument to Freedom/Fly Lokubija Fly. 2022. SCS Centar-Jadro. Skopje, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/68.png",
          caption:
            "Fatherland: A Monument to Freedom/Fly Lokubija Fly. 2022. Video still, color, sound. Video duration: 00:15:52"
        },
        {
          src: "/assets/fatherland/69.png",
          caption:
            "Fatherland: A Monument to Freedom/Fly Lokubija Fly. 2022. Video still, color, sound. Video duration: 00:15:52"
        },
        {
          src: "/assets/fatherland/70.jpg",
          caption:
            "Fatherland: A Monument to Freedom/Fly Lokubija Fly. 2022. Video still, color, sound. Video duration: 00:15:52"
        },
        {
          src: "/assets/fatherland/72.png",
          caption:
            "Fatherland: A Monument to Freedom/Fly Lokubija Fly. 2022. Video still, color, sound. Video duration: 00:15:52"
        },
        {
          src: "/assets/fatherland/73.png",
          caption:
            "Fatherland: A Monument to Freedom/Fly Lokubija Fly. 2022. Video still, color, sound. Video duration: 00:15:52"
        },
        {
          src: "/assets/fatherland/64.jpg",
          caption:
            "Installation view: Fatherland: A Monument to Freedom/Fly Lokubija Fly. 2022. SCS Centar-Jadro. Skopje, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/65.jpg",
          caption:
            "Installation view: Fatherland: A Monument to Freedom/Fly Lokubija Fly. 2022. SCS Centar-Jadro. Skopje, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/66.jpg",
          caption:
            "Installation view: Fatherland: A Monument to Freedom/Fly Lokubija Fly. 2022. SCS Centar-Jadro. Skopje, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/71.jpg",
          caption:
            "Installation view: Fatherland: A Monument to Freedom/Fly Lokubija Fly. 2022. SCS Centar-Jadro. Skopje, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/74.jpg",
          caption:
            "Announcement of the US Ambassadors Fund for Cultural Preservation Grant for the Restoration and Conservation of the Monument to Freedom, Kočani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/75.jpg",
          caption:
            "Announcement of the US Ambassadors Fund for Cultural Preservation Grant for the Restoration and Conservation of the Monument to Freedom, Kočani, North Macedonia. 2022"
        },
        {
          src: "/assets/fatherland/76.jpg",
          caption:
            "Conservation and Restoration of the Monument to Freedom in Kočani, North Macedonia. 2023/24"
        },
        {
          src: "/assets/fatherland/77.jpg",
          caption:
            "Conservation and Restoration of the Monument to Freedom in Kočani, North Macedonia. 2023/24"
        },
        {
          src: "/assets/fatherland/78.jpg",
          caption:
            "Conservation and Restoration of the Monument to Freedom in Kočani, North Macedonia. 2023/24"
        },
        {
          src: "/assets/fatherland/79.jpg",
          caption:
            "Conservation and Restoration of the Monument to Freedom in Kočani, North Macedonia. 2023/24"
        },
        {
          src: "/assets/fatherland/80.jpg",
          caption:
            "Conservation and Restoration of the Monument to Freedom in Kočani, North Macedonia. 2023/24"
        },
        {
          src: "/assets/fatherland/81.jpg",
          caption:
            "Conservation and Restoration of the Monument to Freedom in Kočani, North Macedonia. 2023/24"
        },
        {
          src: "/assets/fatherland/82.jpg",
          caption:
            "Conservation and Restoration of the Monument to Freedom in Kočani, North Macedonia. 2023/24"
        },
        {
          src: "/assets/fatherland/83.jpg",
          caption:
            "Conservation and Restoration of the Monument to Freedom in Kočani, North Macedonia. 2023/24"
        },
        {
          src: "/assets/fatherland/84.jpg",
          caption:
            "Conservation and Restoration of the Monument to Freedom in Kočani, North Macedonia. 2023/24"
        },
        {
          src: "/assets/fatherland/85.jpg",
          caption:
            "Inauguration of the Restored Monument to Freedom in in Kočani, North Macedonia. 2024."
        },
        {
          src: "/assets/fatherland/86.jpg",
          caption:
            "Inauguration of the Restored Monument to Freedom in in Kočani, North Macedonia. 2024."
        },
        {
          src: "/assets/fatherland/87.jpg",
          caption:
            "Inauguration of the Restored Monument to Freedom in in Kočani, North Macedonia. 2024."
        }
      ]
    };
  },
  methods: {
    openGallery(index) {
      EventBus.$emit("open-gallery", index);
    }
  }
};
</script>

<style scoped>
.line-height_08 {
  line-height: 0.8;
}
</style>
