<template>
  <div>
    <ImageComponent
      :src="media[0].src"
      :alt="media[0].caption"
      @click="openGallery(0)"
    >
      <template v-slot:headline>
        <h2>
          1000 Paths Well-Trodden
        </h2>
        <h5>
          paintings, soundscape, stickers, zine, installation
        </h5>
        <h5>2024</h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <p>
              Flesh of the World
            </p>
            <p>
              It starts with a crack. A tear opens in the flesh. From this
              break, something seeps: not blood, but the fabric that connects
              all things. It moves, breathes, and shifts as bodies unravel into
              objects, and objects strain to become flesh.
            </p>
            <p>
              The canvas carries weight. It stretches and shifts, as though
              holding something just beneath the surface. The colors bloom and
              break apart, and the textures carry the memory of decay. There are
              no clear edges here, no lines that hold things in place. A hand
              becomes earth, becomes rust, becomes emptiness. The flesh of the
              world is melting.
            </p>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[1].caption"
                  v-lazy="media[1].src"
                  @click="openGallery(1)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <p>
                  In this change, there is fear—a fear of disintegration, of
                  forces pulling and pushing the world apart. The weight of
                  systems, histories, and desires tears at what once seemed
                  whole. Yet amidst this unraveling, the body continues in its
                  becoming, refusing to remain one thing.
                </p>
                <p>
                  This is not representation; it is participation. The paintings
                  are not observing from the outside—they are inside the tear,
                  inside the transformation. The scene unfolds, boundaries
                  dissolve, and the flesh of the world reshapes itself into
                  something else. The edges of the body blur with the images of
                  the world, and the images, made of the same fabric, return to
                  the body.
                </p>
                <p>
                  Elena Chemerska and Artan Sadiku
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ImageComponent>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[2].caption"
          v-lazy="media[2].src"
          @click="openGallery(2)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[3].caption"
          v-lazy="media[3].src"
          @click="openGallery(3)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[4].caption"
          v-lazy="media[4].src"
          @click="openGallery(4)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[5].caption"
          v-lazy="media[5].src"
          @click="openGallery(5)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[6].caption"
          v-lazy="media[6].src"
          @click="openGallery(6)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[7].caption"
          v-lazy="media[7].src"
          @click="openGallery(7)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[8].caption"
          v-lazy="media[8].src"
          @click="openGallery(8)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[9].caption"
          v-lazy="media[9].src"
          @click="openGallery(9)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[10].caption"
          v-lazy="media[10].src"
          @click="openGallery(10)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[11].caption"
          v-lazy="media[11].src"
          @click="openGallery(11)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[12].caption"
          v-lazy="media[12].src"
          @click="openGallery(12)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[13].caption"
          v-lazy="media[13].src"
          @click="openGallery(13)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4">
        <img
          class="img-fluid"
          :alt="media[14].caption"
          v-lazy="media[14].src"
          @click="openGallery(14)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4">
        <img
          class="img-fluid"
          :alt="media[15].caption"
          v-lazy="media[15].src"
          @click="openGallery(15)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[16].caption"
          v-lazy="media[16].src"
          @click="openGallery(16)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4">
        <img
          class="img-fluid"
          :alt="media[17].caption"
          v-lazy="media[17].src"
          @click="openGallery(17)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4">
        <img
          class="img-fluid"
          :alt="media[18].caption"
          v-lazy="media[18].src"
          @click="openGallery(18)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4">
        <img
          class="img-fluid"
          :alt="media[19].caption"
          v-lazy="media[19].src"
          @click="openGallery(19)"
        />
      </div>
    </div>
    <Gallery :media="media" />
  </div>
</template>

<script>
import ImageComponent from "@/ImageComponent";
import Gallery from "@/Gallery";
import EventBus from "@/eventBus";

export default {
  name: "BoilingBody",
  components: { Gallery, ImageComponent },
  methods: {
    openGallery(index) {
      EventBus.$emit("open-gallery", index);
    }
  },
  data() {
    return {
      media: [
        {
          src: "/assets/roads/0.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/1.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/2.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/3.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/4.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/6.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/5.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/8.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/7.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/9.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/10.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/14.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/15.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/16.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/13.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/17.jpg",
          caption:
            "Exhibition view: 1000 Paths Well-Trodden, 2024. SCS Jadro, Skopje, North Macedonia. Photo by: Dragica Nikolovska."
        },
        {
          src: "/assets/roads/18.jpg",
          caption:
            "Taste of Sand. Oil paint, sand, textured plaster and glue on canvas. 180 cm x 140 cm. 2024."
        },
        {
          src: "/assets/roads/20.jpg",
          caption:
            "Rrrrrghhrrrra! Oil paint on canvas. 180 cm x 140 cm. 2023/24."
        },
        {
          src: "/assets/roads/21.jpg",
          caption: "PSCHOUUUU. Mixed media on canvas. 225cm x 100 cm. 2023."
        },
        {
          src: "/assets/roads/19.jpg",
          caption: "Bones, Bones! Oil paint on canvas. 90 cm x 75 cm. 2024."
        }
      ]
    };
  }
};
</script>

<style scoped></style>
