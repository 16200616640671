<template>
  <div>
    <ImageComponent
      :src="media[0].src"
      :alt="media[0].caption"
      @click="openGallery(0)"
    >
      <template v-slot:headline>
        <h2>
          host or parasite: boiling body
        </h2>
        <h5>
          in collaboration with ivana mirchevska
        </h5>
        <h5>
          installation, video, audio and sculpture
        </h5>
        <h5>2023</h5>
      </template>
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <p>
              Host or Parasite: Boiling Body is an installation work that
              explores the intertwining of environmental degradation and the
              impact of neoliberal capitalism on the individual.
            </p>
            <div class="row">
              <div class="col-12 mt-4">
                <img
                  class="img-fluid"
                  :alt="media[1].caption"
                  v-lazy="media[1].src"
                  @click="openGallery(1)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <p>
                  At a time when planetary depletion aligns with the draining of
                  vitality of neoliberal capitalism, we look into the
                  consequences of bodily exhaustion. Neutralized to the point of
                  numbness, we oscillate between existing tendencies rather than
                  encouraging the emergence of new ones on a planetary scale.
                  Amidst the ebb and flow of specific bodily and somatic
                  capacities, where do we look for zones of safety?
                </p>
                <p>
                  Looking into the contemporary choreographers of care and their
                  concealment of the idea of bodies as repositories of diverse
                  capacities, we delve into an auto-fictional world. Here, in
                  the metabolic entanglement between host and parasite,
                  relations of hospitality, hostility, conviviality and unequal
                  transactions are rehearsed.
                </p>
                <p>
                  Through the refusal to perform according to pre-given
                  orientations, uttered messages, subterraneous noise and
                  flickering surfaces aim to reshape care not as an
                  individualistic anthropocentric approach, but as a pathway of
                  vibrant interdependency.
                </p>
                <h2>collaborators</h2>
                <ul class="standard-list no-padding-list">
                  <li>Sound: Jovan Bliznakovski</li>
                  <li>3D Animation: Maja Cibreva</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ImageComponent>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[2].caption"
          v-lazy="media[2].src"
          @click="openGallery(2)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[3].caption"
          v-lazy="media[3].src"
          @click="openGallery(3)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[4].caption"
          v-lazy="media[4].src"
          @click="openGallery(4)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[5].caption"
          v-lazy="media[5].src"
          @click="openGallery(5)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[6].caption"
          v-lazy="media[6].src"
          @click="openGallery(6)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[7].caption"
          v-lazy="media[7].src"
          @click="openGallery(7)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[8].caption"
          v-lazy="media[8].src"
          @click="openGallery(8)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[9].caption"
          v-lazy="media[9].src"
          @click="openGallery(9)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[10].caption"
          v-lazy="media[10].src"
          @click="openGallery(10)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[11].caption"
          v-lazy="media[11].src"
          @click="openGallery(11)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[12].caption"
          v-lazy="media[12].src"
          @click="openGallery(12)"
        />
      </div>
      <div class="col-12 col-lg-6 mt-4 text-left">
        <img
          class="img-fluid"
          :alt="media[13].caption"
          v-lazy="media[13].src"
          @click="openGallery(13)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-left iframe-container mt-3">
        <iframe
          title="vimeo-player"
          src="https://player.vimeo.com/video/898558892"
          class="responsive-iframe"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <Gallery :media="media" />
  </div>
</template>

<script>
import ImageComponent from "@/ImageComponent";
import Gallery from "@/Gallery";
import EventBus from "@/eventBus";

export default {
  name: "BoilingBody",
  components: { Gallery, ImageComponent },
  methods: {
    openGallery(index) {
      EventBus.$emit("open-gallery", index);
    }
  },
  data() {
    return {
      media: [
        {
          src: "/assets/boilingbody/0.jpg",
          caption:
            "Installation view: Host or Parasite: Boiling Body. 2023. Škuc Gallery, Ljubljana, Slovenia. Photo by Simao Bessa."
        },
        {
          src: "/assets/boilingbody/1.jpg",
          caption:
            "Installation view: Host or Parasite: Boiling Body. 2023. Škuc Gallery, Ljubljana, Slovenia. Photo by Jože Suhadolnik."
        },
        {
          src: "/assets/boilingbody/2.jpg",
          caption:
            "Host or Parasite: Boiling Body. 2023. Video still, colour, sound. Video duration: 00:08:18."
        },
        {
          src: "/assets/boilingbody/3.jpg",
          caption:
            "Host or Parasite: Boiling Body. 2023. Video still, colour, sound. Video duration: 00:08:18."
        },
        {
          src: "/assets/boilingbody/4.jpg",
          caption:
            "Host or Parasite: Boiling Body. 2023. Video still, colour, sound. Video duration: 00:08:18."
        },
        {
          src: "/assets/boilingbody/5.jpg",
          caption:
            "Host or Parasite: Boiling Body. 2023. Video still, colour, sound. Video duration: 00:08:18."
        },
        {
          src: "/assets/boilingbody/6.jpg",
          caption:
            "Installation view: Host or Parasite: Boiling Body. 2024. Mala Stanica, National Gallery of North Macedonia, Skopje, N. Macedonia. Photo by Jelena Belikj"
        },
        {
          src: "/assets/boilingbody/7.jpg",
          caption:
            "Installation view: Host or Parasite: Boiling Body. 2024. Mala Stanica, National Gallery of North Macedonia, Skopje, N. Macedonia. Photo by Jelena Belikj"
        },
        {
          src: "/assets/boilingbody/8.jpg",
          caption:
            "Installation view: Host or Parasite: Boiling Body. 2024. Mala Stanica, National Gallery of North Macedonia, Skopje, N. Macedonia. Photo by Jelena Belikj"
        },
        {
          src: "/assets/boilingbody/11.jpg",
          caption:
            "Installation view: Host or Parasite: Boiling Body. 2024. Mala Stanica, National Gallery of North Macedonia, Skopje, N. Macedonia. Photo by Jelena Belikj"
        },
        {
          src: "/assets/boilingbody/12.jpg",
          caption:
            "Installation view: Host or Parasite: Boiling Body. 2024. Mala Stanica, National Gallery of North Macedonia, Skopje, N. Macedonia. Photo by Jelena Belikj"
        },
        {
          src: "/assets/boilingbody/13.jpg",
          caption:
            "Installation view: Host or Parasite: Boiling Body. 2024. Mala Stanica, National Gallery of North Macedonia, Skopje, N. Macedonia. Photo by Jelena Belikj"
        },
        {
          src: "/assets/boilingbody/9.jpg",
          caption:
            "Host or Parasite: Boiling Body. 2023. Video still, colour, sound. Video duration: 00:08:18."
        },
        {
          src: "/assets/boilingbody/10.jpg",
          caption:
            "Host or Parasite: Boiling Body. 2023. Video still, colour, sound. Video duration: 00:08:18."
        }
      ]
    };
  }
};
</script>

<style scoped></style>
